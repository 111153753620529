import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  FlexRow,
  GridRow,
  NameImageWrapper,
  RowImage,
  RowName,
  RowText,
  TextOverflow,
} from "utils/styled";

import LazyLoadImage from "components/layouts/LazyLoadImage";

const ListNFTCard = ({ item }) => {
  const { _id, nft, sale_price } = item;
  const { name, image, favorites } = nft;
  const to = `/item-details/${_id}`;
  const settings = useSelector((state) => state.settings);

  const renderColumnFields = () => {
    const orderedFields = [
      `${sale_price ? `${sale_price} ${settings.payment.symbol}` : "~"}`,
      favorites,
    ];

    return orderedFields.map((field, index) => (
      <FlexRow key={index}>
        <RowText key={"orderedFields" + index}>{field}</RowText>
      </FlexRow>
    ));
  };

  return (
    <Link to={to}>
      <GridRow>
        <NameImageWrapper>
          <RowImage>
            <LazyLoadImage
              className="launchpad-img-row"
              src={image}
              key={`featured-${_id}`}
            />
          </RowImage>
          <TextOverflow>
            <RowName>{name ?? "~"}</RowName>
          </TextOverflow>
        </NameImageWrapper>
        {renderColumnFields()}
        {/* <button>test</button> */}
      </GridRow>
    </Link>
  );
};

export default ListNFTCard;
