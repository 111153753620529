import { useDispatch, useSelector } from "react-redux";
import {
  RainbowConnectButton,
  useWagmiAccount,
  useWagmiSwitchNetwork,
  useWagmiWalletClient,
} from "@skalenetwork/metaport";
import { connect } from "redux/state/web3";

import LoadingButton from "./LoadingButton";

const RequireConnectedButton = ({ text, disabled, className, children }) => {
  const web3 = useSelector((state) => state.web3);
  const connected = web3.address;
  const wrongNetwork = web3.bad_network;
  const dispatch = useDispatch();
  const { data: wallet } = useWagmiWalletClient({
    chainId: process.env.REACT_APP_NETWORK_ID,
  });

  const { switchNetwork } = useWagmiSwitchNetwork();
  const { address, isConnected } = useWagmiAccount();

  if (!connected) {
    return (
      <RainbowConnectButton.Custom>
        {({ openConnectModal }) => {
          return (
            <button
              className={className}
              disabled={disabled}
              onClick={() => {
                if (isConnected) {
                  if (wallet?.data) {
                    dispatch(connect(address, wallet.data));
                  }
                } else {
                  openConnectModal();
                }
              }}
            >
              Login to {text}
            </button>
          );
        }}
      </RainbowConnectButton.Custom>
    );
  } else if (wrongNetwork) {
    return (
      <LoadingButton
        className={className}
        disabled={disabled}
        onClick={() => {
          switchNetwork(process.env.REACT_APP_NETWORK_ID);
        }}
      >
        Incorrect network, click here to switch
      </LoadingButton>
    );
  }
  return <>{children}</>;
};

export default RequireConnectedButton;
