import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Accordion } from "react-bootstrap-accordion";
import { connect, useDispatch, useSelector } from "react-redux";
import { initFormVals } from "redux/state/initialValues";
import { change, Field, Form, reduxForm } from "redux-form";
import { renderFormV2, RenderSelectInput } from "utils/form";
import { ciStringCompare } from "utils/formats";
import { callMarket, updateRoyaltyFee } from "utils/web3/evm";

import MultiSelectAccordion from "components/layouts/MultiSelectAccordion";
import ToastPopup from "components/utils/ToastPopup";

export const formName = "collection-review";

const ReviewCollectionModal = ({ onSubmit, collection, onHide, ...props }) => {
  if (!collection) {
    return false;
  }

  const dispatch = useDispatch();
  const { pristine, submitting, handleSubmit, reset, error } = props;
  const settings = useSelector((state) => state.settings);
  const form = useSelector((state) => state.form[formName]);
  const tags = settings?.tags;
  const statuses = ["awaiting review", "under review", "denied", "approved"];

  useEffect(() => {
    dispatch(initFormVals(formName, collection));
  }, []);

  const setRoyaltyFee = async () => {
    let currentInfo = await callMarket(settings.market.address, "_marketContracts", [
      collection.address,
    ]);
    let currentFee = parseInt(currentInfo.cut.toString());
    let currentOwner = currentInfo.owner;

    if (
      currentFee != form.values.royalty_fee * 100 ||
      !ciStringCompare(form.values.royalty_reciever, currentOwner)
    ) {
      await updateRoyaltyFee(
        settings.market.address,
        collection.address,
        form.values.royalty_reciever,
        form.values.royalty_fee * 100
      );
      await onSubmit(collection._id, {
        royalty_reciever: form.values.royalty_reciever,
        royalty_fee: form.values.royalty_fee * 100,
      });
    } else {
      ToastPopup("Information already matches onchain data", "error");
    }
  };

  return (
    <Modal
      show={collection}
      onHide={() => {
        reset();
        onHide();
      }}
      size="xl"
    >
      <Modal.Header closeButton></Modal.Header>
      {/*If collection is mysten, there is no transfer_policy, make one*/}

      <div className="modal-body space-y-20 pd-40">
        <div className="tf-list-item tf-section">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-12">
                <div className="flat-form flat-form-wide">
                  <div className="flat-tabs tab-list-item">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Field
                        type="file"
                        name="featured_img"
                        title="Banner Image"
                        className="hideInput"
                        labelClassName="banner"
                        imgClassName="inheritHeight"
                        featuredImage={
                          form?.values?.featured_img || collection.featured_image
                        }
                        component={renderFormV2}
                        accept=".png,.jpg,.jpeg,.gif"
                        containername="required"
                      />
                      <Field
                        type="file"
                        name="logo_img"
                        className="hideInput"
                        labelClassName="square"
                        featuredImage={form?.values?.logo_img || collection.logo_image}
                        component={renderFormV2}
                        accept=".png,.jpg,.jpeg,.gif"
                      />
                      <Accordion title="Project Details" show={true}>
                        <p>{collection.project_details}</p>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Collection Name"
                          required
                          component={renderFormV2}
                        />
                        <Field
                          type="textarea"
                          name="description"
                          placeholder='e.g. "This is my collection!"'
                          required
                          component={renderFormV2}
                        />
                      </Accordion>
                      <div className="row-form style-3">
                        <div className="inner-row-form">
                          <Field
                            type="number"
                            title="Royalties"
                            name="royalty_fee"
                            //disabled={true}
                            component={renderFormV2}
                          />
                        </div>
                        <div className="inner-row-form">
                          <Field
                            type="text"
                            title="Royalty Reciever"
                            name="royalty_reciever"
                            component={renderFormV2}
                          />
                        </div>
                        <div className="inner-row-form">
                          <Button onClick={setRoyaltyFee}>
                            Update royalties onchain
                          </Button>
                        </div>
                      </div>
                      <MultiSelectAccordion
                        item={{ name: "tags", title: "Tags", show: true, values: tags }}
                        formName={formName}
                      />
                      {/* <Accordion title="Metadata" show={true}>
                        <FieldArray
                          name="fields"
                          title="Metadata Information"
                          component={renderSearchFields}
                          rerenderOnEveryChange
                        />
                      </Accordion> */}
                      <Field
                        type="select"
                        name="review_status"
                        component={RenderSelectInput}
                        options={statuses.map((value) => ({
                          value: value,
                          id: value,
                          label: value,
                        }))}
                      />
                      <Field
                        type="checkbox"
                        name="active"
                        checked={form?.values?.active}
                        onClick={() =>
                          dispatch(change(formName, "active", !form?.values?.active))
                        }
                        component={renderFormV2}
                      />
                      <Field
                        type="checkbox"
                        name="verified"
                        checked={form?.values?.verified}
                        onClick={() =>
                          dispatch(change(formName, "verified", !form?.values?.verified))
                        }
                        component={renderFormV2}
                      />
                      <Field
                        type="textarea"
                        name="review_notes"
                        component={renderFormV2}
                      />
                      <p className="error">{error}</p>
                      <button type="submit" disabled={pristine || submitting}>
                        Update Collection
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default connect((state) => {
  let formVals = {};
  if (state.initialValues[formName]) {
    formVals = JSON.parse(JSON.stringify(state.initialValues[formName]));
    formVals.royalty_fee = formVals.royalty_fee / 100;
  }
  return {
    initialValues: formVals, // pull initial values from account reducer
  };
})(reduxForm({ form: formName, enableReinitialize: true })(ReviewCollectionModal));
