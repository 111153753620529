import { Modal } from "react-bootstrap";
import { ipfsConvert } from "utils/formats";
// import AttributesGrid from "../AttributesGrid";

const NFTCongratulationsModal = ({ item: nft, onHide }) => {
  if (!nft) {
    return false;
  }

  const nftImage = ipfsConvert(nft.nft.image);
  // ipfsConvert(nft?.image) ||
  // ipfsConvert(nft?.thumb_url);

  return (
    <Modal show={nft} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body pd-40">
        <div className="list-item">
          <div className="flat-tabs tab-list-item space-y-20">
            <h2 className="text-center">{nft.nft.name}</h2>
            {nftImage && nftImage?.split(".").pop() === "mp4" ? (
              <video style={{ zIndex: 1 }} autoPlay muted loop>
                <source src={nftImage} type="video/mp4" />
              </video>
            ) : (
              <div className="flex justify-content-center">
                <img src={nftImage} style={{ width: "50%" }} />
              </div>
            )}
            <h3>NFT Successfully purchased!</h3>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NFTCongratulationsModal;
