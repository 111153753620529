import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  FlexRow,
  GridRow,
  NameImageWrapper,
  RowImage,
  RowName,
  RowText,
  TextOverflow,
} from "utils/styled";

import LazyLoadImage from "components/layouts/LazyLoadImage";

const ListLaunchpadCard = ({ item }) => {
  const settings = useSelector((state) => state.settings);
  const { _id, launchpad_image: image, totalCount, launchpad_collection } = item;
  const { name, floor, volume, total_sales, favorites } = launchpad_collection;
  const to = `/mintpad/${_id}`;

  const renderColumnFields = () => {
    const orderedFields = [
      `${floor ? `${floor.toFixed(2)} ${settings.payment.symbol}` : "~"}`,
      `${volume ? `${volume.toFixed(2)} ${settings.payment.symbol}` : "~"}`,
      total_sales,
      totalCount,
      favorites,
    ];

    return orderedFields.map((field, index) => (
      <FlexRow key={index}>
        <RowText key={"orderedFields" + index}>{field}</RowText>
      </FlexRow>
    ));
  };

  return (
    <Link to={to}>
      <GridRow>
        <NameImageWrapper>
          <RowImage>
            <LazyLoadImage
              className="launchpad-img-row"
              src={image}
              key={`featured-${_id}`}
            />
          </RowImage>
          <TextOverflow>
            <RowName>{name ?? "~"}</RowName>
          </TextOverflow>
        </NameImageWrapper>
        {renderColumnFields()}
        {/* <button>test</button> */}
      </GridRow>
    </Link>
  );
};

export default ListLaunchpadCard;
