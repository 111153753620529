import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import PageHeader from "components/layouts/PageHeader";
import { useState } from "react";
import { Field, FieldArray, reduxForm, SubmissionError } from "redux-form";
import "react-tabs/style/react-tabs.css";
import { useSelector } from "react-redux";
import {
  addCollection,
  searchCollections,
  updateCollection,
  uploadCollectionImage,
} from "utils/api";
import { renderFormV2, renderSetTags } from "utils/form";
import { useNavigate } from "react-router-dom";
import LoadingButton from "components/button/LoadingButton";
import { sleep } from "utils/time";
import { Accordion } from "react-bootstrap-accordion";
import { IS_PROD } from "utils/environments";
import { tryAgain } from "utils/performance";
import ToastPopup from "components/utils/ToastPopup";
import { isAddress } from "utils/web3/web3Utils";

const formName = "add-collection";

const validate = (values) => {
  const errors = {};
  const MAX_FILE_SIZE = 10 * 1024 * 1024; //10Mb

  if (values.address) {
    if (!isAddress(values.address)) {
      errors.address = "Invalid contract address.";
    }
  }
  if (values) {
    if (!values.logo_img && IS_PROD) {
      errors.logo_img = "Required";
    }
    if (!values.featured_img && IS_PROD) {
      errors.featured_img = "Required";
    }
    if (values.logo_img?.size > MAX_FILE_SIZE) {
      errors.logo_img = "Max file size is 10MB. Please upload a smaller file.";
    }
    if (values.featured_img?.size > MAX_FILE_SIZE) {
      errors.featured_img = "Max file size is 10MB. Please upload a smaller file.";
    }
  }
  return errors;
};

const formatAddress = (val) => {
  const trimmed = val.trim().toLowerCase();
  return trimmed;
};

const AddCollection = (props) => {
  const navigate = useNavigate();
  const { handleSubmit, pristine, submitting, error } = props;
  const form = useSelector((state) => state.form[formName]);
  //  const user = useSelector((state) => state.user);
  //  const settings = useSelector((state) => state.settings);
  const [loadingCollection, setLoadingCollection] = useState(false);
  // const [selectedTab, setSelectedTab] = useState("mysten");

  const submit = async (values) => {
    setLoadingCollection(true);
    try {
      //values.standard = selectedTab;

      const nameTaken = await searchCollections({ name: values.name }).then(
        (res) => res.data.results[0]
      );
      if (nameTaken) {
        throw new SubmissionError({
          name: "Name taken",
          _error: "A collection with that name already exists",
        });
      }

      if (values.royalties === undefined) {
        values.royalties = 500;
      }

      const { data: newCollection } = await tryAgain(
        () => addCollection(values),
        3,
        10_000
      );

      if (newCollection) {
        const id = newCollection.collection._id;
        const token = newCollection.accessToken;
        const images = {};
        if (values.featured_img) {
          await uploadCollectionImage(token, id, values.featured_img, "featured")
            .then((res) => (images.featured_image = res))
            .catch(() => {
              ToastPopup("An error occurred uploading Collection Banner.", "error");
            });
        }
        if (values.logo_img) {
          await uploadCollectionImage(token, id, values.logo_img, "logo")
            .then((res) => (images.logo_image = res))
            .catch(() => {
              ToastPopup("An error occurred uploading Collection Logo.", "error");
            });
        }
        if (images) await updateCollection(id, images);
        navigate(`/collection-details/${newCollection.collection._id}`);
      }
      setLoadingCollection(false);
    } catch (e) {
      console.log(e);
      setLoadingCollection(false);
      throw new SubmissionError({
        name: e.errors?.name || "An error occured",
        _error: e.errors?._error || e.message,
      });
    }
  };
  return (
    <div>
      <Header />
      <PageHeader />
      <div className="tf-list-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <div className="flat-form">
                <div className="flat-tabs tab-list-item">
                  <form onSubmit={handleSubmit(submit)}>
                    <Field
                      name="featured_img"
                      type="file"
                      className="hideInput"
                      featuredImage={form?.values?.featured_img}
                      component={renderFormV2}
                      accept=".png,.jpg,.jpeg,.gif"
                    />
                    <Field
                      name="logo_img"
                      type="file"
                      className="hideInput"
                      labelClassName="square"
                      featuredImage={form?.values?.logo_img}
                      component={renderFormV2}
                      accept=".png,.jpg,.jpeg,.gif"
                    />
                    <Field
                      name="name"
                      title="Collection Name"
                      type="text"
                      placeholder="e.g. Sui Capys"
                      component={renderFormV2}
                      required
                    />
                    <Field
                      name="description"
                      type="textarea"
                      placeholder='e.g. "This is my collection!"'
                      component={renderFormV2}
                      required
                    />
                    <Field
                      type="text"
                      name="whitepaper"
                      title="Whitepaper URL"
                      component={renderFormV2}
                    />
                    <Field
                      type="text"
                      name="website"
                      title="Website URL"
                      component={renderFormV2}
                    />
                    <Accordion title="Tags" show={true}>
                      <FieldArray
                        name="tags"
                        title="Tags"
                        component={renderSetTags}
                        className="pt-24"
                      />
                    </Accordion>
                    <Field
                      name="address"
                      type="text"
                      props={{ maxLength: 42 }}
                      component={renderFormV2}
                      placeholder="e.g. 0x8c7b25f7....6a"
                      parse={formatAddress}
                      required
                    />

                    {/** TODO: Add royalty reciever
                      <Field name="royalties" label="Royalties" component={RoyaltiesButtonGroup} />\
                    */}
                    {/*
                    <NFTStandardTabs
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                    /> <Field
                      type="textarea"
                      name="project_details"
                      props={{
                        placeholder:
                          "Tell us about your project, it may help speed up the approval process.",
                      }}
                      required
                      component={renderFormV2}
                    />
                    */}
                    <p className="error">{error?.toString()}</p>
                    <LoadingButton
                      type="submit"
                      loading={loadingCollection}
                      disabled={pristine || loadingCollection}
                    >
                      Add Collection
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default reduxForm({
  form: formName,
  validate,
})(AddCollection);
