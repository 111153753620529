import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateListing } from "utils/api";

import RefreshButton from "components/button/RefreshButton";
import ListNFTCard from "components/cards/ListNFTCard";
import NFTCard from "components/cards/NFTCard";
import CardList from "components/layouts/explore/Lists/CardList";
import LayoutButtons from "components/layouts/LayoutButtons";
import ListNFTSkeleton from "components/layouts/ListNFTSkeleton";
import BidModal from "components/layouts/modal/BidModal";
import BuyModal from "components/layouts/modal/BuyModal";
import NFTCongratulationsModal from "components/layouts/modal/NFTCongratulationsModal";
import NFTSkeleton from "components/layouts/NFTSkeleton";
import PaginationWrapper from "components/layouts/PaginationWrapper";
import SearchBar from "components/layouts/SearchBar";
import Sorting from "components/layouts/Sorting";
import { useRefreshSearch } from "components/utils/SearchProvider";

const MyFavouritesPanel = ({ title }) => {
  const loading = useSelector((state) => state.search.loading);
  const { refreshSearch, setSortParams } = useRefreshSearch();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const [layout, setLayout] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [congratulationsModal, showCongratulationsModal] = useState(false);
  const isAuction = modalShow?.sale_type === "auction";
  const reduxState = useSelector((state) => state.search.listings);
  const orderedHeaders = ["Name", "Current Price", "Favorites"];

  const sortDropdownList = [
    {
      field: "nft.favorites",
      label: "Most Popular",
      direction: -1,
      icon: <i className="fas fa-heart" />,
    },
    {
      field: "sale_price",
      label: "Price: Low to High",
      direction: 1,
      icon: <i className="fas fa-dollar-sign" />,
    },
    {
      field: "sale_price",
      label: "Price: High to Low",
      direction: -1,
      icon: <i className="fas fa-dollar-sign" />,
    },
    {
      field: "createdAt",
      label: "Created: Newest First",
      direction: 1,
      icon: <i className="fas fa-clock" />,
    },
    {
      field: "createdAt",
      label: "Created: Oldest First",
      direction: -1,
      icon: <i className="fas fa-clock" />,
    },
    {
      field: "end_date",
      label: "Ending Soon",
      direction: 1,
      icon: <i className="fas fa-stopwatch" />,
    },
  ];

  const onBuy = async (item) => {
    showBuyModal(false);
    showCongratulationsModal(item);
  };

  const onBid = (item) => {
    updateListing(item._id);
  };

  const handleHideModal = () => {
    showCongratulationsModal(false);
  };

  return (
    <div>
      <div className="option" style={{ gap: "2rem" }}>
        <div className="count-and-search fullWidth">
          <h2 className="item-count">
            {loading ? "..." : count} {title}
          </h2>
          <SearchBar />
        </div>
        <div className="view">
          <div className="flex">
            <RefreshButton refreshHandler={refreshSearch} loading={loading} />
            <LayoutButtons layout={layout} setLayout={setLayout} />
          </div>
          <Sorting setSortParams={setSortParams} list={sortDropdownList} />
        </div>
      </div>
      <PaginationWrapper pages={pages} count={count}>
        <CardList
          layout={layout}
          title={title}
          setModalShow={setModalShow}
          setCount={setCount}
          setPages={setPages}
          SkeletonComponent={NFTSkeleton}
          CardComponent={NFTCard}
          ListSkeletonComponent={ListNFTSkeleton}
          ListCardComponent={ListNFTCard}
          orderedHeaders={orderedHeaders}
          reduxState={reduxState}
        />
      </PaginationWrapper>
      <BuyModal
        onBuy={onBuy}
        item={!isAuction && modalShow}
        setModalShow={setModalShow}
        // beforeSetListing={refreshSearch}
        onHide={() => setModalShow(false)}
      />
      <BidModal
        onBid={onBid}
        item={isAuction && modalShow}
        setModalShow={setModalShow}
        // beforeSetListing={refreshSearch}
        onHide={() => setModalShow(false)}
      />
      <NFTCongratulationsModal
        item={congratulationsModal}
        onHide={() => handleHideModal()}
      />
    </div>
  );
};

export default MyFavouritesPanel;
