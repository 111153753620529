import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  RainbowConnectButton,
  useWagmiAccount,
  useWagmiWalletClient,
} from "@skalenetwork/metaport";
import logoicon from "assets/images/logo/logo_icon.png";
import { connect } from "redux/state/web3";
import styled from "styled-components";
import { Name, NetworkLogo } from "utils/environments";
import { ellipsifyString } from "utils/formats";

import AutocompleteSearchBar from "components/layouts/AutocompleteSearchBar";
import LoadingSpinner from "components/utils/LoadingSpinner";
import ToastPopup from "components/utils/ToastPopup";

import DarkMode from "./DarkMode";
import HeaderProfile from "./HeaderProfile";
import MainNav from "./MainNav";

const WalletAddress = styled.div`
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  background-color: transparent;
  border: 2px solid var(--primary-color3);
  color: var(--primary-color10);
  cursor: pointer;
  width: max-content;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;

  :hover {
    color: #fff;
    background-color: var(--primary-color3);
  }

  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const web3 = useSelector((state) => state.web3);

  const { address, isConnecting, isConnected } = useWagmiAccount();
  const wallet = useWagmiWalletClient({
    chainId: parseInt(process.env.REACT_APP_NETWORK_ID),
  });

  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header?.classList?.add("is-fixed")
      : header?.classList?.remove("is-fixed");
    scrollTop >= 400
      ? header?.classList?.add("is-small")
      : header?.classList?.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const [profile, toggleProfile] = useState(false);

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  const CopyWallet = () => {
    return (
      <CopyToClipboard
        style={{
          cursor: "pointer",
        }}
        text={user.account_address}
        onCopy={() => ToastPopup("Copied wallet address to clipboard!", "success")}
      >
        <WalletAddress>
          <i className="fal mr-3 fa-copy"></i>
          {ellipsifyString(address, 10)}
        </WalletAddress>
      </CopyToClipboard>
    );
  };

  return (
    <header
      id="header_main"
      className="header_1 js-header flex align-items-center"
      ref={headerRef}
    >
      <div
        className={`avatar_popup_bg ${profile ? "visible" : ""}`}
        onClick={() => toggleProfile(false)}
      ></div>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="flex justify-content-between align-items-center header_1-inner">
                <div className="min-width-max-content">
                  <Link to="/" rel="home" className="main-logo">
                    {/* <NetworkLogo className="logo-icon" /> */}
                    <img
                      className="logo-icon"
                      id="logo_icon"
                      src={logoicon}
                      alt="keepsake-panda"
                    />
                    <Name />
                  </Link>
                </div>
                <AutocompleteSearchBar />
                <MainNav
                  menuLeft={menuLeft}
                  handleOnClick={handleOnClick}
                  pathname={pathname}
                  activeIndex={activeIndex}
                />
                <div className="flat-search-btn flex min-width-max-content">
                  {!user._id || !web3.connected ? (
                    isConnecting ? (
                      <div className="center-margin">
                        <LoadingSpinner size="medium" />
                      </div>
                    ) : (
                      <RainbowConnectButton.Custom>
                        {({ openConnectModal }) => {
                          return (
                            <button
                              onClick={() => {
                                if (isConnected) {
                                  if (wallet?.data) {
                                    dispatch(connect(address, wallet.data));
                                  }
                                } else {
                                  openConnectModal();
                                }
                              }}
                            >
                              {/* <LooksRoundedIcon
                                className={cmn.mri5}
                                style={{ height: '18px', width: '18px' }}
                              /> */}
                              Connect wallet
                            </button>
                          );
                        }}
                      </RainbowConnectButton.Custom>
                    )
                  ) : (
                    <>
                      <HeaderProfile toggleProfile={toggleProfile} profile={profile} />
                      <CopyWallet />
                      {/* <NotificationCenter /> */}
                    </>
                  )}

                  <DarkMode />
                  <div className="mobile-button" ref={btnToggle} onClick={menuToggle}>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
