import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { searchGames } from "utils/api";
import { formatDateForPicker, sumFieldInArray } from "utils/formats";

import FeaturedLaunchpadCountdownCubes from "components/layouts/launchpad/details/FeaturedLaunchpadCountdownCubes";

import "swiper/scss/navigation";
import "swiper/scss/pagination";

import LazyLoadImage from "./LazyLoadImage";

import "swiper/scss";

const InnerSlide = styled.div`
  width: 550px;
  height: 304px;
  overflow: hidden;
`;

const HoverOverlay = styled.div`
  width: 550px;
  height: 304px;
  position: absolute;
  z-index: 20;
  box-shadow: var(--primary-color3) 0px 20px 40px -16px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  :hover {
    opacity: 1;
  }
`;

const Cube = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.12) 100%);
  backdrop-filter: blur(20px);
  min-width: 60px;
  height: 60px;
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 575px) {
    display: none;
  }
`;
const ContentOuter = styled.div`
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  a,
  a:hover {
    color: #fff;
  }
`;
const ContentInner = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ContentTop = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ContentBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;
const Icon = styled.i`
  font-size: 24px;
`;
const LaunchpadTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;
const LaunchpadTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  :hover,
  :hover a {
    color: rgba(255, 255, 255, 0.6);
  }

  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
`;
const LaunchpadDate = styled.h6`
  font-weight: 500;
  font-size: 20px;
  font-family: "Poppins";
  line-height: 1;
  :hover,
  a:hover {
    color: rgba(255, 255, 255, 0.6);
  }
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
`;
const ViewButton = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.12) 100%);
  backdrop-filter: blur(20px);
  min-height: 60px;
  padding: 0 2rem;
  border-radius: var(--border-radius-md);
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  text-transform: uppercase;
  :hover {
    border: none;
    background: #fff;
    color: #000;
  }
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  @media ((max-width: 1366px) and (min-width: 768px)) or (max-width: 500px) {
    min-height: 44px;
    border-radius: var(--border-radius-md);
    font-size: 16px;
    padding: 0 1rem;
  }
`;
const LaunchpadItems = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 1;
  text-transform: uppercase;
`;

const LaunchpadImage = styled.img`
  height: 304px;
  width: 550px;
  object-fit: cover;
`;

const LaunchpadSlide = ({ item }) => {
  const now = formatDateForPicker(new Date());
  const date = new Date(item.start_date);
  const isLive = now > formatDateForPicker(date);
  const stringDate = date.toLocaleString("default", { month: "long", day: "numeric" });
  const [inventories, setInventories] = useState([]);
  const [saleIndex, setSaleIndex] = useState(null);
  const sales = item?.sales || [];

  const isMintingNow = sales.filter(
    (sale) =>
      now > formatDateForPicker(sale.start_date) &&
      now < formatDateForPicker(sale.end_date)
  );

  useEffect(() => {
    const firstPublicIndex = sales?.findIndex(
      (sale) => sale.hidden != true && sale.whitelisted != true
    );
    const firstPrivateIndex = sales?.findIndex(
      (sale) => sale.hidden != true && sale.whitelisted == true
    );
    const defaultIndex = firstPublicIndex > -1 ? firstPublicIndex : firstPrivateIndex;
    setSaleIndex(Math.max(defaultIndex, 0));
  }, [sales]);

  let visibleInventories = [];
  if (inventories && inventories.length > 0) {
    let uniqueTiers = [];
    visibleInventories = sales
      .map((sale, index) => {
        const isUnique = !uniqueTiers.includes(sale.object_id);
        uniqueTiers.push(sale.object_id);
        return isUnique && !sale.whitelisted && !sale.hidden && inventories[index];
      })
      .filter(Boolean);
  }
  const combinedTotalItems = sumFieldInArray(visibleInventories, "total");
  const combinedCurrentItems = sumFieldInArray(visibleInventories, "current");
  const sale = sales[saleIndex] || {};
  const price = sale?.price;

  useEffect(() => {
    // getSaleData(item, inventories, saleIndex)
    //   .then((res) => setInventories(res))
    //   .catch((e) => console.log("error", e));
  }, [item, saleIndex]);

  return (
    <div className="sc-card-product">
      <Link to={`/mintpad/${item._id}`}>
        <div className="card-overlay"></div>
        <HoverOverlay />
        <LaunchpadImage
          height="304px"
          src={item.launchpad_image || item.launchpad_collection.logo_image}
        />
        <ContentOuter>
          <ContentInner>
            <ContentTop>
              <LaunchpadTitleWrapper>
                <LaunchpadTitle>{item.launchpad_collection.name}</LaunchpadTitle>
                {isLive && isMintingNow.length > 0 ? (
                  <div className="flex" style={{ gap: "0.5rem" }}>
                    <span className="mint-option-live">LIVE</span>
                    <div className="pulse green" />
                  </div>
                ) : (
                  <LaunchpadDate>{stringDate}</LaunchpadDate>
                )}
                {/* <LaunchpadItems>
                      {combinedTotalItems || "~"} items · {price} {settings.payment.symbol}
                    </LaunchpadItems> */}
              </LaunchpadTitleWrapper>
            </ContentTop>
            <ContentBottom>
              <FeaturedLaunchpadCountdownCubes
                launchpad={item}
                inventory={{
                  current: combinedCurrentItems,
                  total: combinedTotalItems,
                }}
              />
              <ViewButton>View</ViewButton>
            </ContentBottom>
          </ContentInner>
        </ContentOuter>
      </Link>
    </div>
  );
};

const GameSlide = ({ item }) => {
  return (
    <InnerSlide className="sc-card-product menu_card">
      <Link to={item.link}>
        <HoverOverlay />
        <LazyLoadImage
          height="304px"
          className="collection-img-featured"
          src={item.img}
          key={item.link}
          style={{ height: "304px", objectFit: "cover", width: "100%" }}
        />
      </Link>
    </InnerSlide>
  );
};

const SliderItem = ({ item }) => {
  switch (item.type) {
    case "game":
      return <GameSlide item={item} />;
    case "mintpad":
      return <LaunchpadSlide item={item} />;
    default:
      return null;
  }
};

const HorizontalSlider = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [transformValue, setTransformValue] = useState(null);
  const [featuredLaunchpads, setFeaturedLaunchpads] = useState([]);
  const [featuredGames, setFeaturedGames] = useState([]);
  const [featuredLoading, setFeaturedLoading] = useState(true);

  const shuffledFeatureds = [...featuredGames, ...featuredLaunchpads].sort(
    (a, b) => 0.5 - Math.random()
  );

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = async () => {
    await Promise.all([/*fetchFeaturedLaunchpads(),*/ fetchFeaturedGames()]).then(() =>
      setFeaturedLoading(false)
    );
  };

  // const fetchFeaturedLaunchpads = async () => {
  //   const res = await searchLaunchpads({
  //     review_status: "approved",
  //     featured: true,
  //     page: 1,
  //   });
  //   const results = res.data.results.map((launchpad) => ({
  //     img: launchpad.launchpad_collection.featured_image,
  //     link: `/mintpad/${launchpad._id}`,
  //     type: "mintpad",
  //     ...launchpad,
  //   }));
  //   setFeaturedLaunchpads(results);
  // };

  const fetchFeaturedGames = async () => {
    const res = await searchGames({
      review_status: "approved",
      featured: true,
      page: 1,
    });
    const results = res.data.results.map((game) => ({
      img: game.banner_image,
      link: `/games/${game._id}`,
      type: "game",
    }));
    setFeaturedGames(results);
  };

  const playSwiper = () => {
    const swiperStyle = swiperRef.wrapperEl.style;
    const total = swiperRef.slidesSizesGrid[0] + swiperRef.params.spaceBetween;
    const progressRemaining = swiperRef.getTranslate() - swiperRef.translate;
    const progressPercentage = 1 - progressRemaining / total;
    swiperStyle.transitionDuration =
      swiperRef.params.speed * (1 - progressPercentage.toFixed(3)) + "ms";
    swiperStyle.transform = transformValue;

    swiperRef.autoplay.start();
  };

  const pauseSwiper = () => {
    swiperRef.autoplay.stop();
    const swiperStyle = swiperRef.wrapperEl.style;
    setTransformValue(swiperStyle.transform);
    swiperStyle.transitionDuration = "0ms";
    swiperStyle.transform = "translate3d(" + swiperRef.getTranslate() + "px, 0px, 0px)";
  };

  if (featuredLoading || shuffledFeatureds.length === 0) return null;

  return (
    <Fragment>
      <section className="tf-section horizontal-slider">
        <div
          style={{ height: "100%" }}
          onMouseEnter={() => pauseSwiper()}
          onMouseLeave={() => playSwiper()}
        >
          <div className="slider-overlay" />
          <Swiper
            onSwiper={setSwiperRef}
            modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView="auto"
            preventInteractionOnTransition={true}
            setWrapperSize={true}
            loopedSlides={7}
            loopAdditionalSlides={12}
            loopedSlidesLimit={false}
            centeredSlides={true}
            speed={7000}
            loop={true}
            autoplay={{
              delay: 1,
              disableOnInteraction: false,
            }}
            style={{ overflow: "unset" }}
          >
            {shuffledFeatureds.map((item, index) => (
              <SwiperSlide key={index}>
                <SliderItem item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </Fragment>
  );
};

export default HorizontalSlider;
