import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import PageHeader from "components/layouts/PageHeader";
import ReviewTabs from "components/layouts/ReviewTabs";
import { SearchProvider } from "components/utils/SearchProvider";
import ToastPopup from "components/utils/ToastPopup";

const Review = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  if (user.role_id !== 3) {
    ToastPopup("You do not have permission to view this page", "warning");
    navigate("/");
  }

  return (
    <div className="my-profile">
      <Header />
      <PageHeader />
      <section className="tf-section tf-my-items">
        <div className="themesflat-container">
          <div className="row justify-content-center">
            <div className="col-12 my-items">
              <SearchProvider>
                <ReviewTabs />
              </SearchProvider>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Review;
