const data = {
  chains: [
    "juicy-low-small-testnet", // Europa
    "giant-half-dual-testnet", // Calypso
    "lanky-ill-funny-testnet", // Nebula
    "aware-fake-trim-testnet", // Titan
  ],
  tokens: {
    eth: {
      symbol: "ETH",
    },
    skl: {
      decimals: "18",
      name: "SKALE",
      symbol: "SKL",
    },
    usdc: {
      decimals: "6",
      symbol: "USDC",
      name: "USD Coin",
    },
    usdt: {
      decimals: "6",
      symbol: "USDT",
      name: "Tether USD",
    },
    wbtc: {
      decimals: "8",
      symbol: "WBTC",
      name: "WBTC",
    },
    ruby: {
      name: "Ruby Token",
      iconUrl: "https://ruby.exchange/images/tokens/ruby-square.png",
      symbol: "RUBY",
    },
    dai: {
      name: "DAI Stablecoin",
      symbol: "DAI",
    },
    usdp: {
      name: "Pax Dollar",
      symbol: "USDP",
      iconUrl: "https://ruby.exchange/images/tokens/usdp-square.png",
    },
    hmt: {
      name: "Human Token",
      symbol: "HMT",
      iconUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/10347.png",
    },
    ubxs: {
      name: "UBXS Token",
      symbol: "UBXS",
      decimals: "6",
      iconUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/17242.png",
    },
  },
  connections: {
    "giant-half-dual-testnet": {
      // Calypso connections
      erc20: {
        usdc: {
          address: "0x2aebcdc4f9f9149a50422fff86198cb0939ea165",
          chains: {
            "juicy-low-small-testnet": {
              clone: true,
            },
            "aware-fake-trim-testnet": {
              clone: true,
            },
            mainnet: {
              hub: "juicy-low-small-testnet",
              clone: true,
            },
          },
        },
      },
    },
    "lanky-ill-funny-testnet": {
      // nebula connections
      erc20: {
        usdc: {
          address: "0x5eaf4e5a908ba87abf3de768cb0da517db45db48",
          chains: {
            "juicy-low-small-testnet": {
              clone: true,
            },
            "aware-fake-trim-testnet": {
              clone: true,
            },
            mainnet: {
              hub: "juicy-low-small-testnet",
              clone: true,
            },
          },
        },
      },
    },
    "aware-fake-trim-testnet": {
      // titan connections
      erc20: {
        usdc: {
          address: "0x10a30e73ab2da5328fc09b06443dde3e656e82f4",
          chains: {
            "juicy-low-small-testnet": {
              clone: true,
            },
            "lanky-ill-funny-testnet": {
              clone: true,
            },
            mainnet: {
              hub: "juicy-low-small-testnet",
              clone: true,
            },
          },
        },
      },
    },
    "juicy-low-small-testnet": {
      // Europa connections
      erc20: {
        usdc: {
          address: "0x6CE77Fc7970F6984eF3E8748A3826972Ec409Fb9",
          chains: {
            mainnet: {
              clone: true,
            },
            "giant-half-dual-testnet": {
              wrapper: "0xa6be26f2914a17fc4e8d21a1ce2ec4079eeb990c",
            },
            "lanky-ill-funny-testnet": {
              wrapper: "0xa6be26f2914a17fc4e8d21a1ce2ec4079eeb990c",
            },
            "aware-fake-trim-testnet": {
              wrapper: "0xa6be26f2914a17fc4e8d21a1ce2ec4079eeb990c",
            },
          },
        },
      },
    },
  },
};

const config = {
  ...data,
  openOnLoad: true,
  openButton: true,
  skaleNetwork: "testnet",
  theme: {
    mode: "dark",
    vibrant: true,
  },
  // mainnetEndpoint: "??",
  projectId: process.env.REACT_APP_WALLET_CONNECT_ID,
};

export default config;
