import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNFTsVisibilityManager } from "redux/persist/profileSettings";

import RefreshButton from "components/button/RefreshButton";
import ToggleButton from "components/button/ToggleButton";
import LoanModal from "components/layouts/modal/LoanModal";
import NFTDetailsModal from "components/layouts/modal/NFTDetailsModal";
import SellModal from "components/layouts/modal/SellModal";
import PaginationWrapper from "components/layouts/PaginationWrapper";
import MyOwnedNFTsList from "components/layouts/profile/Lists/MyOwnedNFTsList";
import SearchBar from "components/layouts/SearchBar";
import { useRefreshSearch } from "components/utils/SearchProvider";

const MyOwnedNFTsPanel = ({ title }) => {
  const loading = useSelector((state) => state.web3.nft_contents.loading);
  const { refreshSearch, setCustomParams, setSortParams } = useRefreshSearch();
  const [sellModalShow, setSellModalShow] = useState(false);
  const [loanModalShow, setLoanModalShow] = useState(false);
  const [nftDetailsModalShow, setNftDetailsModalShow] = useState(false);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const [showHiddenNFTs, setHiddenNFTsVisibility] = useNFTsVisibilityManager();

  return (
    <div>
      <div className="option" style={{ gap: "2rem" }}>
        <div className="count-and-search fullWidth">
          <h2 className="item-count">
            {loading ? "..." : count} {title}
          </h2>
          <SearchBar />
        </div>
        <RefreshButton refreshHandler={refreshSearch} loading={loading} />
        <div className="input-row">
          <ToggleButton
            input={{ readOnly: true, checked: showHiddenNFTs }}
            onChange={setHiddenNFTsVisibility}
          />
        </div>
      </div>
      <PaginationWrapper pages={pages} count={count}>
        <MyOwnedNFTsList
          setSellModalShow={setSellModalShow}
          setLoanModalShow={setLoanModalShow}
          setNftDetailsModalShow={setNftDetailsModalShow}
          setCount={setCount}
          setPages={setPages}
          showHiddenNFTs={showHiddenNFTs}
        />
      </PaginationWrapper>
      <SellModal item={sellModalShow} onHide={() => setSellModalShow(false)} />
      <LoanModal item={loanModalShow} onHide={() => setLoanModalShow(false)} />
      <NFTDetailsModal
        item={nftDetailsModalShow}
        onHide={() => setNftDetailsModalShow(false)}
      />
    </div>
  );
};

export default MyOwnedNFTsPanel;
