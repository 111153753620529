import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import { getCollection, updateLoanListing } from "utils/api";
import { ipfsConvert } from "utils/formats";
import { sleep } from "utils/time";

import LoadingButton from "components/button/LoadingButton";
import ToastPopup from "components/utils/ToastPopup";

const HaltLoanModal = ({ item, onBuy, onHide }) => {
  if (!item) {
    return false;
  }
  const [rented, setRented] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nftCollection, setNftCollection] = useState({});

  useEffect(() => {
    fetchCollection();
  }, []);

  const fetchCollection = async () => {
    if (item.collection) {
      setNftCollection(item.collection);
    } else {
      setLoading(true);
      const nftCollection = await getCollection(item.nft_collection);
      setNftCollection(nftCollection.data.collection);
      setLoading(false);
    }
    if (item.loan_expiration) {
      let now = new Date();
      let expires = new Date(item.loan_expiration);
      setRented(now.getTime() < expires.getTime());
    }
    // let listing = await getObjectInfo(item.listing_object_id);
    // if (!listing) {
    //   ToastPopup("Listing has already been removed.", "error");
    //   await updateLoanListing(item._id);
    //   if (onBuy) {
    //     onBuy(item);
    //   }
    //   onHide();
    // }
  };

  const unlistListing = async (item) => {
    setLoading(true);
    try {
      // let res = rented
      //   ? await haltLendingNFT({ ...item, ...{ nft_collection: nftCollection } })
      //   : await finishLendingNFT({ ...item, ...{ nft_collection: nftCollection } });
      // await sleep();
      // if (res?.status === "success") {
      //   await updateLoanListing(item._id);
      //   if (onBuy) {
      //     onBuy(item);
      //   }
      //   onHide();
      // }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const finishRenderer = ({ formatted, completed }) => {
    const { days, hours, minutes, seconds } = formatted;
    if (completed) {
      return "";
    }
    return <p>Rental Ends in: {`${days}:${hours}:${minutes}:${seconds}`}</p>;
  };

  return (
    <Modal show={item} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40">
        <h3>{rented ? "Halt" : "Remove"} this listing?</h3>
        {rented ? (
          <p>
            You will be able to retrieve your NFT after the current rental duration has
            finished
          </p>
        ) : (
          <p>Your NFT will be returned to your kiosk</p>
        )}
        <h5 className="text-center">{item.nft.name}</h5>
        <img src={ipfsConvert(item.nft.image)} />
        {item.loan_expiration && (
          <div>
            <>
              <Countdown
                date={item.loan_expiration}
                renderer={finishRenderer}
                onComplete={() => setRented(true)}
              >
                <></>
              </Countdown>
            </>
          </div>
        )}

        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={() => unlistListing(item)}
        >
          {rented ? "Halt" : "Remove"} listing
        </LoadingButton>
      </div>
    </Modal>
  );
};

export default HaltLoanModal;
