import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { searchListings } from "redux/state/search";
import { updateListing } from "utils/api";
import { ListGrid } from "utils/styled";

import NFTCard from "components/cards/NFTCard";

import BidModal from "./modal/BidModal";
import BuyModal from "./modal/BuyModal";
import UnlistModal from "./modal/UnlistModal";

const NewestNFTs = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.search.listings.results);
  const user = useSelector((state) => state.user);
  const [buyLoading, setBuyLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [visible, setVisible] = useState(12);
  const isOwner = user._id === modalShow.seller;

  const refreshListings = () =>
    dispatch(searchListings({ sortParams: { createdAt: -1 } }));

  useEffect(() => {
    refreshListings();
  }, []);

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 6);
  };

  const onBuy = (item) => {
    // updateListing(item._id);
  };

  const handleHideModal = () => {
    setModalShow(false);
  };

  return (
    <section className="tf-section live-auctions">
      <div className="themesflat-container">
        <div className="heading-live-auctions">
          <h2 className="tf-title pb-22 text-left">Newest NFTs</h2>
          <Link to="/explore/listings">
            <div className="border-button">
              <span className="exp">VIEW ALL</span>
            </div>
          </Link>
        </div>
        <ListGrid>
          {data.slice(0, visible).map((item) => (
            <NFTCard key={item._id} item={item} setModalShow={setModalShow} />
          ))}
        </ListGrid>
        {visible < data.length && (
          <div className="col-md-12 wrap-inner load-more text-center mt-4">
            <Link
              to="#"
              id="load-more"
              className="sc-button loadmore fl-button pri-3"
              onClick={showMoreItems}
            >
              <span>Load More</span>
            </Link>
          </div>
        )}
      </div>
      {isOwner ? (
        <UnlistModal
          item={modalShow}
          onHide={() => handleHideModal()}
          customFetch={refreshListings}
        />
      ) : (
        <BuyModal
          onBuy={onBuy}
          item={modalShow}
          setModalShow={setModalShow}
          beforeSetListing={refreshListings}
          onHide={() => {
            handleHideModal();
          }}
        />
      )}
    </section>
  );
};

export default NewestNFTs;
