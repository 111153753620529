import React, { useEffect, useMemo, useState } from "react";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ipfsConvert, listingPriceName } from "utils/formats.js";

import WishlistButton from "components/button/WishlistButton";
import LazyLoadImage from "components/layouts/LazyLoadImage";

const NFTCard = ({ buttonText, item, checkWon, setModalShow, alwaysShowButton }) => {
  const { _id, nft, auction, sale_type, collection, seller, result } = item;
  const [finished, setFinished] = useState(false);
  const [started, setStarted] = useState(false);
  const [canUnlist, setCanUnlist] = useState(sale_type !== "auction");
  const user = useSelector((state) => state.user);
  const settings = useSelector((state) => state.settings);

  const isCurrentBidder = auction && user?._id === auction.bidder;
  const isOwner = user._id === seller;
  const won = checkWon && finished && isCurrentBidder;
  const priceClass =
    auction && checkWon ? (isCurrentBidder ? "winning-bid" : "losing-bid") : "";
  const disabled =
    (isOwner ? !canUnlist : auction && ((finished && !won) || !started)) ||
    result === "sold";

  useEffect(() => {
    if (auction) {
      const now = Date.now();
      const start = new Date(auction.start_date);
      const end = new Date(auction.end_date);
      if (now < end) {
        setCanUnlist(true);
      }
      if (now > start) {
        setStarted(true);
      }
    }
  }, []);

  const onComplete = () => {
    setFinished(true);
    if (isOwner && isCurrentBidder) {
      setCanUnlist(true);
    } else {
      setCanUnlist(false);
    }
  };

  const finishRenderer = ({ formatted, completed }) => {
    const { days, hours, minutes, seconds } = formatted;
    if (completed) {
      return "Auction Finished";
    }
    return (
      <div className="featured-countdown ends-in">
        Ends in: {`${days}:${hours}:${minutes}:${seconds}`}
      </div>
    );
  };

  const startRenderer = ({ formatted, completed }) => {
    const { days, hours, minutes, seconds } = formatted;
    if (completed) {
      return (
        <Countdown
          date={auction?.end_date}
          renderer={finishRenderer}
          onComplete={onComplete}
        />
      );
    }
    return (
      <div className="featured-countdown starts-in">
        Starts in: {`${days}:${hours}:${minutes}:${seconds}`}
      </div>
    );
  };

  const unlistRenderer = ({ formatted }) => {
    const { hours, minutes, seconds } = formatted;
    return <>Unlist in: {`${hours}:${minutes}:${seconds}`}</>;
  };

  const buyText = useMemo(() => {
    if (isOwner) {
      if (canUnlist && result !== "sold") {
        return "Edit Listing";
      } else {
        if (sale_type === "auction") {
          const end = new Date(auction.end_date);
          const unlistDate = new Date(auction.end_date);
          unlistDate.setDate(end.getDate() + 1);

          return (
            <Countdown
              date={unlistDate}
              renderer={unlistRenderer}
              onComplete={() => setCanUnlist(true)}
            />
          );
        }
      }
    } else {
      if (!buttonText) {
        return sale_type !== "auction" ? (result === "sold" ? "Sold" : "Buy") : "Bid";
      } else {
        return buttonText;
      }
    }
  }, [finished, user?._id, canUnlist]);

  const handleModalShow = (item) => {
    setModalShow(item);
  };

  return (
    <div className="sc-card-product">
      <div className={`card-media${alwaysShowButton ? ` active` : ``}`}>
        <Link to={`/item-details/${_id}`}>
          {nft?.image && nft?.image?.split(".").pop() === "mp4" ? (
            <video className="image-skeleton" style={{ zIndex: 1 }} autoPlay muted loop>
              <source src={ipfsConvert(nft.image)} type="video/mp4" />
            </video>
          ) : (
            <LazyLoadImage src={ipfsConvert(nft.image)} className="image-skeleton" />
          )}
        </Link>

        <WishlistButton nft={nft} />
        {auction && (
          <div className="featured-countdown">
            <Countdown
              className="start"
              date={auction?.start_date}
              renderer={startRenderer}
            />
          </div>
        )}
      </div>
      <div className="card-details">
        <div className="card-title flex justify-content-between">
          <div className="flex flex-column" style={{ maxWidth: "60%" }}>
            <Link to={`/item-details/${_id}`}>
              <h3 className="nft-text text-truncate">{nft?.name}</h3>
            </Link>
            <Link to={`/collection-details/${collection?._id}`}>
              <h5 className="nft-text text-truncate">{collection?.name}</h5>
            </Link>
          </div>
          <div className="price">
            <span>{listingPriceName(item)}</span>
            <h5 className={priceClass}>
              {item.sale_price} {settings.payment.symbol}
            </h5>
          </div>
        </div>
        {setModalShow && (
          <div className="button-place-bid">
            <button
              onClick={() => handleModalShow(item)}
              disabled={disabled}
              data-toggle="modal"
              data-target="#popup_bid"
              className="sc-button style-place-bid fl-button pri-3 fullWidth"
            >
              <span>{buyText}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NFTCard;
