import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { renderDuration } from "utils/formats";
import {
  FlexRow,
  GridRow,
  NameImageWrapper,
  RowImage,
  RowName,
  RowText,
  TextOverflow,
} from "utils/styled";

import LazyLoadImage from "components/layouts/LazyLoadImage";

const ListRentalCard = ({ item }) => {
  const { _id, duration, end_date, ask_per_day, loan_expiration, nft } = item;
  const { name, image, favorites } = nft;
  const [rented, setRented] = useState(false);
  const to = `/rental-details/${_id}`;
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    const now = Date.now();
    if (loan_expiration) {
      const rentedOut = new Date(loan_expiration);
      if (rentedOut > now) {
        setRented(rentedOut);
      }
    }
  }, []);

  const renderColumnFields = () => {
    const orderedFields = [
      `${rented ? dayjs(rented).format("MMM D hh:mmA") : "Not Rented"}`,
      renderDuration(duration?.min),
      renderDuration(duration?.max),
      `${dayjs(end_date).format("MMM D h:mma")}`,
      `${ask_per_day ? `${ask_per_day.toFixed(2)} ${settings.payment.symbol}` : "~"}`,
      favorites,
    ];

    return orderedFields.map((field, index) => (
      <FlexRow key={index}>
        <RowText key={"orderedFields" + index}>{field}</RowText>
      </FlexRow>
    ));
  };

  return (
    <Link to={to}>
      <GridRow>
        <NameImageWrapper>
          <RowImage>
            <LazyLoadImage
              className="launchpad-img-row"
              src={image}
              key={`featured-${_id}`}
            />
          </RowImage>
          <TextOverflow>
            <RowName>{name ?? "~"}</RowName>
          </TextOverflow>
        </NameImageWrapper>
        {renderColumnFields()}
        {/* <button>test</button> */}
      </GridRow>
    </Link>
  );
};

export default ListRentalCard;
