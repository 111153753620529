// The goal of this hook is to update web3 data across the app and keep it consistent
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWagmiAccount, useWagmiWalletClient } from "@skalenetwork/metaport";
import { useWagmiSwitchNetwork } from "@skalenetwork/metaport";
import { connect, getUserCoins } from "redux/state/web3";
import { isValidNetwork } from "utils/web3/web3Utils";

const useWeb3NetworkData = () => {
  const dispatch = useDispatch();

  const { address, status } = useWagmiAccount();
  const { data: walletClient } = useWagmiWalletClient();

  const settings = useSelector((state) => state.settings);
  const web3 = useSelector((state) => state.web3);
  const [hasProvider, setHasProvider] = useState(false);

  const { switchNetwork } = useWagmiSwitchNetwork();

  const checkChain = async () => {
    let chainId = await walletClient.getChainId();
    if (isValidNetwork(chainId)) {
      dispatch(connect(address, walletClient));
    } else if (switchNetwork) {
      if (!web3.metaport) {
        switchNetwork(process.env.REACT_APP_NETWORK_ID);
      }
    }
  };

  useEffect(() => {
    if (walletClient) {
      if (!hasProvider && status == "connected") {
        setHasProvider(true);
        dispatch(connect(address, walletClient));
      }
      // prevent metaport from locking up when needing to switch to nebula
      checkChain();
    }
  }, [walletClient]);

  useEffect(() => {
    if (walletClient && address != web3.address) {
      dispatch(connect(address, walletClient));
    }
  }, [address]);

  useEffect(() => {
    if (status == "disconnected") {
      setHasProvider(false);
    }
  }, [status == "disconnected"]);

  useEffect(() => {
    if (web3.address && !web3.bad_network && settings.ready) {
      dispatch(getUserCoins(settings, true));
    }
  }, [web3.address, web3.bad_network, settings]);
};

export default useWeb3NetworkData;
