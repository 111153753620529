import { Modal } from "react-bootstrap";
import { Explorer } from "utils/environments";
import { ipfsConvert } from "utils/formats";

import AttributesGrid from "components/layouts/AttributesGrid";

const NFTDetailsModal = ({ item: nft, onHide }) => {
  if (!nft) {
    return false;
  }
  const nftImage = ipfsConvert(nft?.image || nft?.thumb_url);

  return (
    <Modal show={nft} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body pd-40">
        <div className="list-item">
          <div className="flat-tabs tab-list-item space-y-20 ">
            <h2 className="text-center">{nft?.name}</h2>
            {nftImage && nftImage?.split(".").pop() === "mp4" ? (
              <video style={{ zIndex: 1 }} autoPlay muted loop>
                <source src={nftImage} type="video/mp4" />
              </video>
            ) : (
              <img src={nftImage} />
            )}
            <button className="btn-block">
              <a
                className="heading"
                href={`${Explorer}/token/${nft.collection.address}/instance/${nft.token_id}/token-transfers`}
                target="_blank"
                rel="noreferrer"
              >
                <h5 className="text-center">View on Explorer</h5>
              </a>
            </button>
            {nft.fields && (
              <div>
                <AttributesGrid
                  collectionID={nft?.nft_collection}
                  nftFields={nft.fields}
                  saleType="sale"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NFTDetailsModal;
