import React, { Children, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getCollectionsForGame, getTopCollections } from "utils/api";
import {
  FlexColumn,
  GridHeader,
  HeaderText,
  ListGrid,
  NoResultsTextWrapper,
  PrimaryText,
  RowContainer,
  SecondaryText,
} from "utils/styled";

import LeaderboardRow from "components/layouts/LeaderboardRow";

const SectionHeader = styled.h2`
  padding-bottom: 22px;
  line-height: 44px;
  color: var(--primary-color2);
  font-size: 24px;

  @media screen and (min-width: 575px) {
    font-size: 36px;
  }
`;

const RowText = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

const RowTextSmall = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: rgb(138, 147, 155);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const HeaderSort = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 0.75rem;
  font-size: 12px;
  user-select: none;
  :first-child {
    justify-content: flex-start;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: center;
`;

const GameCollectionsLeaderboard = ({ title, game_id }) => {
  const [collections, setCollections] = useState([]);
  const [initialCollections, setInitialCollections] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [page, setPage] = useState(1);
  const perPage = 10;
  const [visible, setVisible] = useState(perPage);
  const settings = useSelector((state) => state.settings);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 10);
  };

  const onLoad = async () => {
    setLoading(true);
    const res = await getCollectionsForGame(game_id);
    setCollections(res.data.results);
    setInitialCollections(structuredClone(res.data.results));
    setTimeout(() => setLoading(false), 300);
  };

  useEffect(() => {
    if (sortBy.length === 0) {
      setCollections(initialCollections);
      return;
    }
    let sortedCollections = [...collections].sort((col_a, col_b) => {
      if (sortBy[1] === -1) {
        return col_a[sortBy[0]] - col_b[sortBy[0]];
      } else if (sortBy[1] === 1) {
        return col_b[sortBy[0]] - col_a[sortBy[0]];
      } else {
        return 0;
      }
    });
    setCollections(sortedCollections);
  }, [sortBy]);

  const orderedHeaders = [
    { header: "Owners", field: "owners" },
    { header: "Listed", field: "listed" },
    { header: "Total Sales", field: "total_sales" },
    { header: "Active Supply", field: "nft_count" },
    { header: "Floor", field: "floor" },
    { header: "%Change (1h)", field: "percent_change" },
    { header: "Volume (1h)", field: "volume_change" },
    { header: "Total Volume", field: "volume" },
  ];

  const renderColumnHeaders = () => {
    return orderedHeaders.map((column, index) => {
      const thisField = sortBy[0] === column.field;
      const isSortAsc = thisField && sortBy[1] === 1;
      const isSortDesc = thisField && sortBy[1] === -1;
      return (
        <HeaderSort key={`column` + index} onClick={() => changeSortBy(index)}>
          <HeaderText className={`${thisField && `mint-text`}`}>
            {column.header}
          </HeaderText>
          <FlexColumn>
            <i
              className={`fas fa-angle-up ${isSortAsc && ` mint-text`}`}
              style={{ height: "8px" }}
            />
            <i className={`fas fa-angle-down ${isSortDesc && ` mint-text`}`} />
          </FlexColumn>
        </HeaderSort>
      );
    });
  };

  const renderColumnFields = (collection) => {
    const {
      owners,
      listed,
      total_sales,
      nft_count,
      floor,
      percent_change,
      volume_change,
      volume,
    } = collection;

    const listedPercent =
      nft_count > 0 ? ((listed / nft_count) * 100).toFixed(2) : "0.00";

    const orderedFields = [
      owners,
      [listed, `(${listedPercent}%)`],
      total_sales,
      nft_count,
      `${floor ? `${floor.toFixed(2)} ${settings.payment.symbol}` : "~"}`,
      `${percent_change}%`,
      `${volume_change.toFixed(2)} ${settings.payment.symbol}`,
      `${volume.toFixed(2)} ${settings.payment.symbol}`,
    ];

    return orderedFields.map((field, index) =>
      typeof field == "object" && field.length > 0 ? (
        <FlexRow key={index}>
          <FlexColumn style={{ alignItems: "center" }} key={"orderedFields" + index}>
            <RowText>{field[0]}</RowText>
            <RowTextSmall>{field[1]}</RowTextSmall>
          </FlexColumn>
        </FlexRow>
      ) : (
        <FlexRow key={index}>
          <RowText key={"orderedFields" + index}>{field}</RowText>
        </FlexRow>
      )
    );
  };

  const changeSortBy = (index) => {
    const field = orderedHeaders[index].field;
    if (sortBy.length > 0 && field === sortBy[0]) {
      if (sortBy[1] === 1) {
        setSortBy([orderedHeaders[index].field, -1]);
      } else {
        setSortBy([]);
      }
    } else {
      setSortBy([orderedHeaders[index].field, 1]);
    }
  };

  useEffect(() => {
    game_id && onLoad();
  }, []);

  return (
    <section className="tf-section games-leaderboard">
      <div className="themesflat-container">
        <FlexColumn>
          {collections?.length === 0 ? (
            <NoResultsTextWrapper>
              <PrimaryText>Collections Leaderboard</PrimaryText>
              <SecondaryText>There are no collections at this time</SecondaryText>
            </NoResultsTextWrapper>
          ) : (
            <>
              <SectionHeader>{title}</SectionHeader>
              <div style={{ overflowX: "auto" }}>
                <GridHeader>
                  <HeaderSort>
                    <HeaderText>Collection</HeaderText>
                  </HeaderSort>
                  {renderColumnHeaders()}
                </GridHeader>
                <RowContainer>
                  {collections?.slice(0, visible).map((item, index) => (
                    <LeaderboardRow
                      key={"leaderboardRow" + index}
                      index={index}
                      item={item}
                      renderColumnFields={renderColumnFields}
                    />
                  ))}
                </RowContainer>
              </div>
              <div className="mt-4">
                {visible < collections.length && (
                  <div className="col-md-12 wrap-inner load-more text-center">
                    <Link
                      to="#"
                      id="load-more"
                      className="sc-button loadmore fl-button pri-3 mt-10"
                      onClick={showMoreItems}
                    >
                      <span>Load More</span>
                    </Link>
                  </div>
                )}
              </div>
            </>
          )}
        </FlexColumn>
      </div>
    </section>
  );
};

export default GameCollectionsLeaderboard;
