import { ReactComponent as Calypso } from "assets/images/logo/calypso.svg";
import logoCalypso from "assets/images/logo/logo.png";
import logoNebula from "assets/images/logo/logoNebula.png";
import { ReactComponent as Nebula } from "assets/images/logo/nebula.svg";

export const IS_PROD = process.env.REACT_APP_ENV === "production";
export const IS_TESTNET =
  process.env.REACT_APP_NETWORK_NAME.includes("testnet") &&
  process.env.REACT_APP_ENV != "development";
export const Explorer = `https://${process.env.REACT_APP_NETWORK_NAME}.explorer.${
  IS_TESTNET ? "testnet" : "mainnet"
}.skalenodes.com`;

export const NetworkLogo = ({ network, ...props }) => {
  let name = network || process.env.REACT_APP_NETWORK_NICKNAME;
  if (name.includes("Calypso")) {
    return <Calypso {...props} />;
  } else {
    return <Nebula {...props} />;
  }
};

export const Name = () => {
  let name = process.env.REACT_APP_NETWORK_NICKNAME;
  return (
    <img
      className="hidden-xs-down"
      id="logo_header"
      src={name.includes("Calypso") ? logoCalypso : logoNebula}
      // srcSet={`${logodark2x}`}
      alt="nft-gaming"
    />
  );
};
